<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Agendar Pagamentos</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Agendar Pagamentos" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-schedule-payments />
      <list-schedule-payments />
    </q-page>
  </q-layout>
</template>

<script>
import HeaderSchedulePayments from '../../components/financial/HeaderSchedulePayments.vue';
import ListSchedulePayments from '../../components/financial/ListSchedulePayments.vue';

export default {
  name: 'SchedulePayments',
  components: {
    HeaderSchedulePayments,
    ListSchedulePayments
  }
};
</script>
