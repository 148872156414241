<template>
  <div>
    <q-table
      flat
      bordered
      :data="schedulePayments"
      row-key="id"
      class="q-mt-md"
      :columns="columns"
      selection="multiple"
      :pagination.sync="pagination"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
          <q-th auto-width />
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.value }}
          </q-td>
          <q-td auto-width>
            <q-btn
              v-if="props.row.commission.length > 0"
              size="md"
              color="primary"
              round
              unelevated
              dense
              @click="toggleExpand(props.rowIndex)"
              :icon="props.rowIndex === expandedAffiliateIndex ? 'remove' : 'add'"
            />
          </q-td>
        </q-tr>
        <q-tr v-show="props.rowIndex === expandedAffiliateIndex" :props="props">
          <q-td colspan="100%" class="q-pa-none" style="padding: 0">
            <q-table
              :data="props.row.commission.map((el, index) => ({ ...el, affiliate_id: props.row.affiliate_id, index }))"
              flat
              hide-bottom
              row-key="index"
              selection="multiple"
              :selected.sync="selectedChild[props.rowIndex]"
              :columns="columnsChild"
              :pagination="{ rowsPerPage: 0 }"
            >
              <template v-slot:header-selection="scope">
                <q-checkbox
                  color="dark"
                  v-model="scope.selected"
                  checked-icon="mdi-checkbox-multiple-marked-outline"
                  unchecked-icon="mdi-checkbox-multiple-blank-outline"
                  indeterminate-icon="mdi-format-list-checks"
                >
                  <q-tooltip
                    content-class="bg-secondary text-white"
                    content-style="font-size: 12px"
                    anchor="top end"
                    self="center middle"
                  >
                    Marcar / Desmarcar todos
                  </q-tooltip>
                </q-checkbox>
              </template>
            </q-table>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent row q-gutter-sm items-center" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          <div class="col-2">
            <div class="row items-center">
            <span class="q-mr-md">Registros por página:</span>
            <q-select
                v-model="pagination.rowsPerPage"
                :options="[10, 20, 50, 100]"
                dense
                @input="onRowsPerPageChange"
                style="max-width: 60px; padding-left: 8px; padding-right: 8px;"
            />
            <span>Total: {{ reqPagination.total }}</span>
            </div>
          </div>
          <div class="col-4" />
          <div class="col-4 text-right">
            <span class="text-subtitle1 text-bold">Total de Comissões: {{ totalCommissionsFormatted }}</span>
          </div>
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>

    <div class="q-ma-md q-gutter-md q-layout-gt-xs-row">
      <q-btn
        color="positive"
        label="Agendar Pagamento"
        @click="onBtnScheduleClicked"
        no-caps
        unelevated
        class="q-px-md"
        :disable="!this.selectedChild.some((x) => x.length > 0)"
      />
      <q-btn
        color="negative"
        label="Recusar Pagamento"
        @click="onBtnRefuseClicked"
        no-caps
        unelevated
        class="q-px-md"
        :disable="!this.selectedChild.some((x) => x.length > 0)"
      />
    </div>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  name: 'ListSchedulePaymentsNew',
  components: {},
  mixins: [FinancialMixin],

  data() {
    return {
      totalCommissions: null,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      },
      data: null,
      selectedChild: [],
      expandedAffiliateIndex: -1,
      columns: [
        {
          name: 'affiliate_id',
          required: true,
          label: 'Id',
          align: 'left',
          field: 'affiliate_id'
        },
        {
          name: 'affiliate_name',
          label: 'Afiliado',
          align: 'left',
          field: (row) => row.affiliate[0]?.name || 'N/A'
        },
        {
          name: 'created_at',
          label: 'Data de Criação',
          align: 'left',
          field: (row) => this.$moment(row.created_at.split('T')[0]).format('L')
        },
        {
          name: 'value',
          label: 'Comissão',
          field: 'commission',
          format: this.getTotalCommissionValue,
          align: 'left'
        }
      ],
      columnsChild: [
        {
          name: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name'
        },
        {
          name: 'month',
          required: true,
          label: 'Data',
          align: 'left',
          field: 'month',
          format: (val, row) => val + '/' + row.year
        },
        {
          name: 'value',
          required: true,
          label: 'Valor das Comissoes',
          align: 'left',
          field: 'value',
          format: this.parseMoney
        }
      ]
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page && this.reqPagination.from != null) {
        this.getSchedulePayments(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  created() {
    this.getSumTotalComissions();

    const storedRowsPerPage = localStorage.getItem('rowsPerPage');
    if (storedRowsPerPage) {
        this.pagination.rowsPerPage = parseInt(storedRowsPerPage, 10);
    }
    this.getSchedulePayments();
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    totalCommissionsFormatted() {
      if (this.totalCommissions !== null && typeof this.totalCommissions === 'string') {
        const numericValue = parseFloat(this.totalCommissions.replace(/\s/g, '').replace(',', '.'));
        if (!isNaN(numericValue)) {
          return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
        }
      }
      return 'R$ 0,00';
    }
  },

  methods: {
    toggleExpand(index) {
      if (this.expandedAffiliateIndex === index) {
        this.expandedAffiliateIndex = -1;
      } else {
        this.expandedAffiliateIndex = index;
      }
    },
    getTotalCommissionValue(commissions) {
      return commissions
        .map((item) => parseFloat(item.value))
        .reduce((a, b) => a + b, 0)
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },

    async onBtnScheduleClicked() {
      this.onLoading(true);
      const affiliatesAll = [].concat.apply([], this.selectedChild);
      let selectedAffiliates = affiliatesAll.filter((x) => x != undefined);

      let campaignsParse = [];
      for (let affiliate in selectedAffiliates) {
        campaignsParse.push({
          affiliate_id: selectedAffiliates[affiliate].affiliate_id,
          campaign_id: selectedAffiliates[affiliate].campaign_id,
          month: selectedAffiliates[affiliate].month,
          year: selectedAffiliates[affiliate].year
        });
      }

      try {
        const { status } = await this.$http.post('/v2/redirect', {
          app: 'finance',
          path: '/api/invoices_payments/v2',
          params: {
            data: campaignsParse
          }
        });
        if (status === 200) {
          this.ActionSetFilterSchedulePayments({});
          this.getSchedulePayments();
          this.successNotify('Solicitação de pagamento realizada com sucesso!');

          this.selectedChild = [];

          this.expandedAffiliateIndex = -1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onBtnRefuseClicked() {
      const affiliatesAll = [].concat.apply([], this.selectedChild);
      let selectedAffiliates = affiliatesAll.filter((x) => x != undefined);

      let campaignsParse = [];
      for (let affiliate in selectedAffiliates) {
        campaignsParse.push({
          affiliate_id: selectedAffiliates[affiliate].affiliate_id,
          campaign_id: selectedAffiliates[affiliate].campaign_id,
          month: selectedAffiliates[affiliate].month,
          year: selectedAffiliates[affiliate].year
        });
      }

      try {
        const { status } = await this.$http.post('/v2/redirect', {
          app: 'finance',
          path: '/api/invoices_payments/v2/refuse',
          params: {
            data: campaignsParse
          }
        });
        if (status === 200) {
          this.ActionSetFilterSchedulePayments({});
          this.getSchedulePayments();
          this.successNotify('Solicitação de pagamento RECUSADA!');

          this.selectedChild = [];

          this.expandedAffiliateIndex = -1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getSumTotalComissions() {
      try {
        const response = await this.$http.get('/redirect?app=FINANCE&path=/api/payment_requests/v2/get-sum-total-commissions');
        if (response.status === 200) {
            if (response.data && response.data.total_commissions !== undefined) {
                this.totalCommissions = response.data.total_commissions;
            }
        }
      } catch (error) {
        console.error(error);
      }
    },

    onRowsPerPageChange(value) {
        this.pagination.rowsPerPage = value;
        localStorage.setItem('rowsPerPage', value);
        this.getSchedulePayments('page=1', `%26perPage=${value}`);
    },
  }
};
</script>
