<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-select
            v-model="params.affiliate"
            use-input
            input-debounce="200"
            @filter="filterAffiliates"
            :options="affiliatesFiltered"
            label="Nome do afiliado"
            outlined
            option-label="name"
            option-value="id"
            clearable
            @input="(value) => (params.affiliateNameId = value.id)"
          />
        </div>
        <div class="col-6">
          <q-input v-model="params.affiliateId" outlined label="ID do afiliado" type="text" clearable />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterCampaigns" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  name: 'HeaderPayInvoices',
  mixins: [FinancialMixin],
  data() {
    return {
      affiliates: [],
      affiliatesFiltered: [],
      filter: true,
      params: {
        affiliateNameId: null,
        affiliate: null,
        affiliateId: null,
        invoiceId: null,
        invoiceStatus: null,
        invoiceDate: null,
        paymentDate: null
      }
    };
  },
  methods: {
    filterAffiliates(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.affiliatesFiltered = this.affiliates;
        this.affiliatesFiltered = this.affiliates.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async getAffiliates() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/get/affiliate/list');
        if (status === 200)
          this.affiliates = data.map((item) => ({
            id: item.id,
            name: `${item.name} - ${item.business_name ? item.business_name : ''}  (ID: ${item.id}) `
          }));
      } catch (error) {
        console.error(error);
      }
    },

    clearFilter() {
      this.params = {
        affiliateNameId: null,
        affiliate: null,
        affiliateId: null,
        invoiceId: null,
        invoiceStatus: null,
        invoiceDate: null,
        paymentDate: null
      };
      this.setupPage();
    },

    setupPage() {
      this.ActionSetFilterSchedulePayments({});
      this.getSchedulePayments();
    },

    onFilterCampaigns() {
      let filterSelect = [];

      if (this.params.affiliate) filterSelect.push(`affiliate_id=${this.params.affiliateNameId}`);
      else if (this.params.affiliateId) filterSelect.push(`affiliate_id=${this.params.affiliateId}`);

      if (this.params.invoiceId) filterSelect.push(`id=${this.params.invoiceId}`);
      if (this.params.invoiceStatus) filterSelect.push(`states=${this.params.invoiceStatus}`);

      if (this.params.invoiceDate) filterSelect.push(`payment_day=${this.params.invoiceDate}`);

      if (filterSelect.length > 0) {
        this.ActionSetFilterSchedulePayments(filterSelect);
        this.getSchedulePayments(this.filterSchedulePayments);
      } else {
        this.ActionSetFilterSchedulePayments({});
        this.getSchedulePayments();
      }
    }
  },
  created() {
    this.getAffiliates();

    this.setupPage();
  }
};
</script>

<style></style>
